
export const serverAppConfig = {
  "storeId": "62b45c78052ce4126af4112c",
  "analytics": null,
  "privacy": null,
  "storeType": "B2C",
  "storeDeliveries": [
    {
      "id": "62f22d70a92edf5ce162d3af",
      "name": "Delivery 1",
      "label": "1-3 dagar",
      "buyable": true
    },
    {
      "id": "62f22d85a92edf5ce162d3b0",
      "name": "Delivery 2",
      "label": "3-5 dagar",
      "buyable": true
    },
    {
      "id": "62f22d91a92edf5ce162d3b1",
      "name": "Delivery 3",
      "label": "5-8 dagar",
      "buyable": true
    },
    {
      "id": "62f22d9fa92edf5ce162d3b2",
      "name": "Delivery 4",
      "label": "1-2 veckor",
      "buyable": true
    },
    {
      "id": "62f22dada92edf5ce162d3b3",
      "name": "Delivery 5",
      "label": "2-4 veckor",
      "buyable": true
    },
    {
      "id": "62f22dbba92edf5ce162d3b4",
      "name": "Delivery 6",
      "label": "4-6 veckor",
      "buyable": true
    },
    {
      "id": "62f22dc8a92edf5ce162d3b5",
      "name": "Delivery 7",
      "label": "6-8 veckor",
      "buyable": true
    },
    {
      "id": "62f22dd3a92edf5ce162d3b6",
      "name": "Delivery 8",
      "label": "8-10 veckor",
      "buyable": true
    },
    {
      "id": "62f22de0a92edf5ce162d3b7",
      "name": "Delivery 9",
      "label": "Obestämd tid",
      "buyable": true
    },
    {
      "id": "62f22deca92edf5ce162d3b8",
      "name": "Delivery 10",
      "label": "Ej i lager",
      "buyable": true
    },
    {
      "id": "63331c31a4f7cb461c06021e",
      "name": "Delivery 11",
      "label": "4-6 månader",
      "buyable": true
    },
    {
      "id": "63401e14b9dc3fbeb3d05009",
      "name": "Delivery 12",
      "label": "6-8 månader",
      "buyable": true
    }
  ],
  "language": "sv",
  "currency": null,
  "labels": {},
  "paths": {
    "brandsUrlPath": "/varumarken",
    "categoriesUrlPath": "/categories",
    "tagsUrlPath": "/t",
    "productsUrlPath": "/p",
    "blogsUrlPath": "/blog"
  },
  "staticLinks": [
    {
      "id": "brands",
      "label": "Varumärken",
      "path": "/varumarken"
    },
    {
      "id": "blog",
      "label": "Blogg",
      "path": "/blog"
    },
    {
      "id": "minasidor",
      "label": "",
      "path": "/account"
    }
  ],
  "logoUrl": "https://storage.googleapis.com/gecko-media/EC_1034/tags/gasspisen-logo-dark.png",
  "showCart": true,
  "sortingOptions": [
    {
      "sortBy": "price",
      "sortOrder": "DESC",
      "sortLabel": "Pris högt-lågt",
      "default": false
    },
    {
      "sortBy": "price",
      "sortOrder": "ASC",
      "sortLabel": "Pris lågt-högt",
      "default": true
    },
    {
      "sortBy": "created",
      "sortOrder": "DESC",
      "sortLabel": "Nyaste",
      "default": false
    }
  ],
  "desktopItemHeight": "20vw",
  "mobileItemHeight": "45vw",
  "sliderDefaultValues": [
    0,
    300000
  ],
  "freeShippingOver": 999,
  "shippingProviderConfig": {
    "provider": "INGRID",
    "simpleShipping": {
      "minimumOrderAmountForFreeDelivery": 999,
      "deliveryFee": 139
    },
    "ingridOptions": null
  },
  "paymentProviderConfig": {
    "providerName": "KLARNA",
    "providerOptions": {
      "klarna": {
        "purchaseCountry": "SE",
        "purchaseCurrency": "SEK",
        "phoneMandatory": true,
        "colorButton": null,
        "termsUrl": "/kopvillkor",
        "cancellationTermsUrl": "/kopvillkor#angerratt"
      }
    }
  },
  "filtersBeforeShowMore": 3,
  "sliderMaxPrice": 300000,
  "topNavHeight": {
    "panel": {
      "base": 0,
      "sm": "5vh"
    },
    "content": {
      "base": "8vh",
      "sm": "14vh"
    },
    "spacer": {
      "base": "8vh",
      "sm": "19vh"
    },
    "hero": {
      "base": "92vh",
      "sm": "81vh",
      "md": "60vh"
    }
  },
  "topNavSlideOutFeaturedCategories": 3,
  "socialMediaItems": [
    {
      "label": "Facebook",
      "icon": "FACEBOOK",
      "href": "https://www.facebook.com/gasspisen/?view_public_for=104695747628337"
    },
    {
      "label": "Instagram",
      "icon": "INSTAGRAM",
      "href": "https://www.instagram.com/gasspisen/?hl=sv"
    }
  ],
  "topNavPanel": {
    "items": [
      {
        "icon": "FaCrown",
        "label": "Om oss",
        "href": "/about-us"
      },
      {
        "icon": "MdOutlineLocalShipping",
        "label": "Fri frakt till tomtgräns"
      },
      {
        "icon": "FaCrown",
        "label": "VÅRA SHOWROOM",
        "href": "/showrooms"
      }
    ]
  }
};
export const serverTheme = {}; 
